.hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-section h1 {
  font-size: 5rem;
  text-align: center;
}

.hero-section h2 {
  text-align: center;
  font-size: 2.5rem;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  filter: drop-shadow(0px 5px 5px rgba(66, 0, 110, 0.089));
}

.techStackImage img {
  height: 8rem;
  filter: drop-shadow(0px 5px 5px rgba(66, 0, 110, 0.314));
}

.python-logo {
  margin-left: 0vw;
}

.django-logo {
  margin-left: 10vw;
}

.html-logo {
  margin-left: 20vw;
}

.javascript-logo {
  margin-left: 30vw;
}

.react-logo {
  margin-left: 40vw;
}

.css-logo {
  margin-left: 50vw;
}

.rest-logo {
  margin-left: 60vw;
}

.git-logo {
  margin-left: 70vw;
}

.bootstrap-logo {
  margin-left: 80vw;
}

.postgresql-logo {
  margin-left: 90vw;
}

/* PROJECTS */

.project-title-link {
  text-decoration: none;
  color: rgb(49, 5, 79);  
  transition: ease-in-out 0.3s;
}

.project-title {
  transition: ease-in-out 0.3s;
}

.project-title:hover {
  transform: scale(1.015);
  transition: ease-in-out 0.3s;
}

.project-description {
  min-width: 475px;
  max-width: 706px;
  position: absolute;
  top: 20vh;
  padding-left: 3rem;
  box-shadow: 20px 20px 60px #ffffff, -20px -20px 60px #ffffff;
  border-radius: 1rem;
  background-color: #ffffffc1;
}

.project-description h1 {
  font-size: 4rem;
}

.project-description p {
  font-family: "Jersey 10", sans-serif;
  font-size: 1.7rem;
  padding-top: 1rem;
}

.project-tech-stack {
  width: 75%;
}

.project-tech-stack img {
  height: 5rem;
  padding: 0.7rem;
  filter: drop-shadow(0px 5px 5px rgba(66, 0, 110, 0.247));
}

.mobile-mockup {
  position: absolute;
  right: -3rem;
  height: 30rem;
}

.tablet-mockup {
  position: absolute;
  right: 28rem;
}

.laptop-mockup {
  position: absolute;
  right: 5rem;
  height: 20rem;
}

.desktop-mockup {
  position: absolute;
  right: 12rem;
  height: 25rem;
}

.retro-laptop-mockup {
  position: absolute;
  right: -3rem;
  height: 20rem;
}

.retro-desktop-mockup {
  position: absolute;
  right: 25rem;
  height: 25rem;
}

.footer {
  padding-top: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-buttons-line {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.footer-buttons-line a {
  padding: 1rem;
  font-size: 3rem;
  color: rgb(106, 6, 183);
  filter: drop-shadow(0px 5px 5px rgba(66, 0, 110, 0.247));
}