@import "~bootstrap/scss/bootstrap";

@media (max-width: 768px) {
  .landing-mobile {
    display: block;
  }

  .landing-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .landing-mobile {
    display: none;
  }

  .landing-desktop {
    display: block;
  }
}
