.about-container {
  display: flex;
  justify-content: center;
}

.about-card {
  width: 60rem;
  padding: 1rem;
  margin-top: 20vh;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  border-radius: 1rem;
  background-color: #fffffff2;
  font-size: 1.5rem;
  display: flex;
}

.avatar-and-links-container {
  padding-top: 1rem;
}

.dan-image {
  height: 12rem;
  margin: 1rem;
}

.links-container {
  display: flex;
  justify-content: center;
}

.links-container i {
  margin: 0.6rem;
  font-size: 2.5rem;
  color: rgb(101, 1, 168);
  transition: 0.3s;
}

.links-container i:hover {
  color: rgb(185, 121, 228);
  transform: scale(1.1);
  transition: 0.3s;
}

.links-container img {
  height: 3rem;
  padding: 5px;
}
  

.contact-button {
  border-radius: 3rem;
  margin: 1rem;
  background-color: rgba(44, 2, 73, 0.9);
  font-size: 1.3rem;
  color: rgb(224, 177, 255);
  padding: 0.4rem 2.5rem 0.4rem 2.5rem;
  font-family: "Jersey 10", sans-serif;
  display: flex;
  align-items: center;
}

.contact-button i {
  margin-left: 1rem;
}

.cv-button {
  display: flex;
  align-items: center;
  border-radius: 3rem;
  margin: 1rem;
  border-color: rgb(101, 1, 168);
  background-color: transparent;
  font-size: 1.5rem;
  color: rgb(101, 1, 168);
  font-family: "Jersey 10", sans-serif;
  padding: 0 2.5rem 0 2.5rem;
}

.cv-button i {
  margin-right: 1rem;
}